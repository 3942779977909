import {
  EditorComponent,
  useHelpers,
} from '@remirror/react';

import SendButton from './SendButton';
import { useEffect, useState } from 'react';
import { Workflow } from '@contractool/schema/Workflow';
import { cx } from 'utils/classnames';
import { MentionSuggestor } from './MentionSuggestion';

type Props = {
  onSubmit?: (json: any) => void;
  hideButton?: boolean;
  isFocused?: boolean;
  workflow: Workflow;
  type: 'input' | 'textarea' | 'wysiwyg';
  disableMentions?: boolean;
};

const Editor: React.FC<Props> = ({
  onSubmit,
  hideButton,
  isFocused,
  workflow,
  type,
  disableMentions,
}) => {
  const [getMentions, setGetMentions] = useState(false);

  const { getCharacterCount } = useHelpers(true);

  const isTyping = getCharacterCount() > 0;

  useEffect(() => {
    if (isFocused) {
      setGetMentions(true);
    }
  }, [isFocused]);

  return (
    <>
      {getMentions && !disableMentions && <MentionSuggestor workflow={workflow} />}
      <div
        className={cx(
          'w-full flex items-center rounded-lg mt-4',
          type === 'wysiwyg' ? 'border-b remirror-wysiwyg' : 'bg-gray-000 ',
        )}
      >
        <div
          className="w-full"
          onClick={() => {
            if (getMentions === false) {
              disableMentions ? null : setGetMentions(true);
            }
          }}
        >
          <EditorComponent />
        </div>
        {type !== 'wysiwyg' && isTyping && !hideButton && (
          <SendButton onSubmit={onSubmit} />
        )}
      </div>
    </>
  );
};

export default Editor;
