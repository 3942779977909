import { User } from '@contractool/schema';
import { Workflow } from '@contractool/schema/Workflow';
import { FloatingWrapper, useMentionAtom } from '@remirror/react';
import { AvatarLink } from 'components/Avatar';
import { useRequest } from 'hooks/useRequest';
import { useEffect, useState } from 'react';
import { MentionAtomNodeAttributes } from 'remirror/extensions';
import { cx } from 'utils/classnames';
import { translate } from 'utils/translations';

export const MentionSuggestor: React.FC<{ workflow: Workflow }> = ({
  workflow,
}) => {
  const [options, setOptions] = useState<MentionAtomNodeAttributes[]>([]);

  const { state, getMenuProps, getItemProps, indexIsSelected } = useMentionAtom(
    {
      items: options,
    },
  );

  const [response] = useRequest<User[]>('api/users', [], {
    params: { phrase: state?.query?.full },
  });

  useEffect(() => {
    if (!state) {
      return;
    }

    if (state.name === 'at') {
      const users = response.map((user) => ({
        id: user.username,
        url: user.avatar_url,
        label: user.name_email,
      }));
      setOptions(users);
    }

    if (state.name === 'hash') {
      const meta = workflow?.fields.meta_data || [];
      const roles = workflow?.roles || [];
      const tags = [
        ...meta.map((meta) => ({ label: meta.label, id: meta.name })),
        ...roles.map((role) => ({
          label: `${translate('Roles')} - ${role.label}`,
          id: 'role-' + role.key,
        })),
        {
          label: `${translate('Roles - Current user')}`,
          id: 'role-current',
        },
      ];
      tags.push({ label: translate('Project title'), id: 'project_title' });
      tags.push({ label: translate('Contract name'), id: 'contract_name' });
      tags.push({ label: translate('Recipient'), id: 'recipient' });
      tags.push({ label: translate('Created at'), id: 'created_at' });
      tags.push({ label: translate('Updated at'), id: 'updated_at' });
      tags.push({ label: translate('Workflow'), id: 'workflow' });
      tags.push({ label: translate('Approval - Title'), id: 'approvalTitle' });
      tags.push({
        label: translate('Approval - Approver'),
        id: 'approvalApprover',
      });
      tags.push({ label: translate('Approval - Body'), id: 'approvalBody' });
      tags.push({
        label: translate('Approval - Deadline'),
        id: 'approvalDeadline',
      });
      tags.push({ label: translate('Approval - Link'), id: 'approvalLink' });

      const filter = tags.filter((tag) =>
        tag.label.toLowerCase().includes(state.query.full),
      );

      setOptions(filter);
    }
  }, [state, workflow, response]);

  const enabled = Boolean(state);

  return (
    <FloatingWrapper
      positioner="cursor"
      useFloatingPortal={true}
      enabled={enabled}
      placement="bottom-start"
      containerClass="bg-white rounded-lg shadow-lg  w-[350px]"
    >
      <div
        {...getMenuProps()}
        className="suggestions py-2 max-h-96 overflow-y-auto"
      >
        {enabled && options.length > 0 ? (
          options.map((item, index) => {
            const isHighlighted = indexIsSelected(index);

            return (
              <div
                key={item.id}
                className={cx(
                  isHighlighted && 'bg-gray-000 ',
                  'flex items-center text-gray-700 w-full py-2 px-5 hover:bg-gray-000 cursor-pointer',
                )}
                {...getItemProps({
                  item: item,
                  index,
                })}
              >
                {state?.name === 'at' && (
                  <AvatarLink
                    avatar_url={item.url}
                    name={item.label}
                    className="w-8 h-8 mr-3"
                  />
                )}
                {item.label}
              </div>
            );
          })
        ) : (
          <div className="text-gray-700 pl-4 pr-4">
            {translate('No items available')}
          </div>
        )}
      </div>
    </FloatingWrapper>
  );
};
