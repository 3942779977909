import { useHelpers, useKeymap, useRemirrorContext } from '@remirror/react';
import { useCallback } from 'react';

import { translate } from 'utils/translations';

type Props = {
  onSubmit: (json: any) => void;
};

const SendButton: React.FC<Props> = ({ onSubmit }) => {
  const { getJSON } = useHelpers();
  const { clearContent } = useRemirrorContext();

  const onSave = useCallback(
    ({ state }) => {
      onSubmit(getJSON(state));
      clearContent();
      // Prevents any further key handlers from being run.
      return true;
    },
    [getJSON],
  );

  useKeymap('Shift-Enter', onSave);

  return (
    <div className="p-4">
      <button
        className="focus:outline-none text-blue-700 hover:text-blue-400"
        onClick={onSave}
      >
        {translate('Send')}
      </button>
    </div>
  );
};

export default SendButton;
